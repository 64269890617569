@tailwind base;
@tailwind components;
@tailwind utilities;

.fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

#bgvid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  #bgvid {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  #bgvid {
    width: auto;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .fullscreen { background: url(./images/europebycar.info.3.jpg) #000 no-repeat center center fixed; }
  #bgvid { display: none; }
}
